import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "absoluteslots",
  casinoId: 28,
  GA_TRACKING:'G-FR7EHJT1SX',

  rivalChatGroupName: "Slot Powers",
  prettyName: "Slot Powers",
  contactEmail: "support@slotpowers.com",
  docsEmail: "documents@slotpowers.email",

  assetsPath: "assets/brands/slotpowers",

  //SEO
  metaDescription: "Join Slot Powers Online casino and get free spins, no deposit bonus, welcome bonuses and real cash rewards! Play high-quality slots, gamble with live table dealers, get fast and secured payouts from Slot Powers Casino. Bitcoin, crypto, visa, American express and many more accepted.",
  logoName: "slot-powers-online-casino-logo.png",
  logoAltText: "Slot Powers Casino online logo with bold red and blue lettering featuring a slot machine lever known for slots tournaments and casino promotions."
};


